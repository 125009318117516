import { useEffect } from "react";
import { navigate }  from "gatsby";
// SEO •••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••••


// ================================================================================================================== \\
// =============================================== 404 PAGE ========================================================= \\

export default function NotFound() {
    useEffect(() => {
        navigate("/");
    }, []);

    return null;
}
